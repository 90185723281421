.root {
  transition: .2s;
  cursor: pointer;
  transition: .2s;
  color: var(--inline-link-light-cl);

  &.dark {
    color: var(--inline-link-dark-cl);
  }

  &:hover {
    color: var(--inline-link-light-cover-cl);
  }

  &.dark:hover {
    color: var(--inline-link-dark-cover-cl);
  }
}