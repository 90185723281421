.root {
  padding: 60px 0;
  max-width: 100%;
  background-color: var(--section-light-cl);
  overflow: hidden;

  &.dark {
    background-color: var(--section-dark-cl);
  }

  @media (max-width: 600px) {
    padding: 40px 0;
  }
}