.root {
  text-align: center;

  .button {
    border: 1px solid var(--red);
    background-color: transparent;
    font-size: 1.8rem;
    margin: 10px;
    padding: 5px 0;
    width: 4.7rem;
    border-radius: 3px;
    transition: .2s;

    &:nth-child(2) {
      border: 1px solid var(--dark-blue);
    }

    &:first-child.active {
      background-color: var(--red);
      color: white;
    }

    &:nth-child(2).active {
      background-color: var(--dark-blue);
      color: white;
    }
  }

  @media (max-height: 359px) {
    .button {
      margin: 5px 8px;
      padding: 3px 0;
      width: 4.2rem;
      font-size: 1.6rem;
    }
  }
}