.root {
  margin: 1rem 0;
  list-style: none;

  .item {
    margin: .8rem 0;
    font-size: 1.8rem;
  }

  @media (max-width: 500px) {
    display: flex;
    justify-content: center;
    align-items: center;
    
    .item {
      margin: .5rem 2rem;
    }

    &.columnOnSmall {
      flex-direction: column;
    }
  }
}