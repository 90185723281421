.root {
  position: relative;

  .link {
    display: inline-flex;
    align-items: center;
    padding: 0 1.5rem;
    height: 100%;
    line-height: 1;
    font-size: 1.8rem;
    color: var(--nav-bar-link-cl);
    cursor: pointer;
    transition: .2s;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: .3rem;
      background-color: var(--nav-bar-link-hover-cl);
      opacity: 0;
      transition: .2s;
    }

    &:hover, &.active {
      color: var(--nav-bar-link-active-cl);

      &::after {
        opacity: 1;
      } 
    }
  }

  @media (max-width: 700px) {
    .link {
      font-size: 1.7rem;
    }
  }
}