.root {
  padding: 3rem 2rem;
  background-color: #121212;
  font-size: 1.1rem;
  color: #ccc;
  text-align: center;

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1280px;
    margin: 2rem auto;

    .column {
      flex: 1;
      margin: 0 2rem;
    }
  }

  @media (max-width: 800px) {
    .column.sectionsColumn {
      display: none;
    }
  }

  @media (max-width: 500px) {
    .column {
      width: 100%;
    }

    .column.contactColumn {
      margin-top: .5rem;

      h3 {
        display: none;
      }

      ul > li {
        font-size: 1.6rem;
      }
    }
  }

  @media (max-width: 320px) {

    .container {
  
      .column {
        margin: 0;
      }
    }
  }
}