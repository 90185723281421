.root {
  .infoBox {
    position: absolute;
    padding: 1rem;
    border-radius: 5px;
    background-color: var(--skills-info-box-cl);
    box-shadow: 2px 2px 8px #000;

    img {
      height: 2rem;
    }
  }

  .smallDescription {
    top: 20%;
    left: 20%;
    width: 23%;
    transform: translate(-50%, -50%);
    text-align: justify;
    font-size: 2rem;

    .boldName {
      font-weight: bold;
    }
  }
  
  .graph {
    top: 50%;
    left: 80%;
    width: 20%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
  }

  .technology {
    display: flex;
    align-items: center;
    margin: 1rem 0;

    span {
      margin-left: 1rem;
    }
  }

  .nestedList {
    margin-top: -.7rem;
    margin-left: 3.6rem;
  }

  @media (max-width: 1600px) {
    .smallDescription {
      left: 17%;
      width: 25%;
    }

    .graph {
      left: 83%;
      width: 23%;
    }
  }

  @media (max-width: 1280px) {
    .smallDescription {
      left: 13%;
      font-size: 1.8rem;
    }

    .graph {
      left: 88%;
      font-size: 1.8rem;
    }
  }

  @media (max-width: 1024px) {
    position: absolute;
    top: 50%;
    left: 68%;
    width: 50%;
    transform: translate(-50%, -50%);

    .infoBox {
      margin: 10px 0;
      position: static;
      width: 100%;
      transform: none;

      .technology {
        margin: .5rem 0;
      }
    }
  }

  @media (max-width: 800px) {
    left: 72%;

    .infoBox {
      font-size: 1.6rem;

      img {
        height: 1.6rem;
      }
    }
  }

  @media (max-width: 600px) {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 95%;

    .infoBox {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      span {
        margin-left: 0;
      }

      .graphItem {
        flex: 0 0 27%;
        height: 5.5rem;
        text-align: center;
        margin: 5px;
      }

      .technology {
        margin: 0 auto;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        height: 100%;
        font-size: 1rem;
        align-items: center;

        .icon {
          font-size: 3rem;
        }

        img {
          height: 3rem;
        }
      }
      
      .nestedList {
        display: none;
      }
    }
  }
}