.root {
  font-size: inherit;
  transition: .2s;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;

  &.light {
    color: var(--icon-light-cl);

    &:hover {
      color: var(--icon-light-hover-cl);
    }
  }

  &.dark {
    color: var(--icon-dark-cl);

    &:hover {
      color: var(--icon-dark-hover-cl);
    }
  }

  &.circle {
    display: inline-block;
    text-align: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color:var(--icon-light-round-bg-cl);
    color: var(--icon-light-round-cl);
    font-size: 26px;
    line-height: 36px;

    &.dark {
      background-color: var(--icon-dark-round-bg-cl);
      color: var(--icon-dark-round-cl);

      &:hover {
        background-color: var(--icon-dark-round-bg-hover-cl);
        color: var(--icon-dark-round-hover-cl);
      }
    }

    &:hover {
      background-color: var(--icon-light-round-bg-hover-cl);
      color: var(--icon-light-round-hover-cl);
    }
  }
}