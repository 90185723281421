.root {
  display: none;

  .background {
    position: fixed;
    top: -55px;
    right: -55px;
    width: 100px;
    height: 100px;
    background: var(--nav-mobile-bg-cl);
    transform: scale(.1);
    border-radius: 50%;
    transition: .5s;
  }

  .box {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 200px;
    opacity: 0;
    transform: translate(500px, -50%);
    transition: .5s;
  }

  .list {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.active {
    .background {
      transform: scale(30);
    }

    .box {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }

  @media (max-width: 600px) {
    display: block;
  }
}