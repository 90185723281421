.root {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  width: 40rem;
  max-width: 90%;
  background-color: #fff;
  font-size: 1.8rem;
  text-align: center;
  
  .content {
    margin: 1.5rem;
  }
}