:root {
  /* Fonts */
  --main-font: 'Roboto', sans-serif;

  /* Colors */

  //basic colors
  --white: #fff;
  --dark-white: #ccc;
  --light-grey: #aaa;
  --light-grey2: #bbb;
  --dark-grey: #555;
  --dark-grey2: #444;
  --grey: #888;
  --main-dark: #222;
  --black: #000;
  --light-red: #ff7247;
  --red: #ff2c1f;
  --orange: #ffa500;
  --orange-hover: #ff8800;
  --dark-orange: #ff4500;
  --yellow: #ffff00;
  --yellow-light: #dada1a;
  --very-light-green: #76ff03; 
  --light-green: #00cf2d;
  --green: #1cac1c;
  --dark-green: #1f271f;
  --blue: #00c2fd;
  --dark-blue: #004cff;

  //components colors
    //header colors
    --header-cl: var(--main-dark);

    //page title Component colors
    --title-cl: var(--white);
    --title-name-cl: var(--red);
    --title-description-cl: var(--light-grey);
    --title-link-cl: var(--white);
    --title-link-active-cl: var(--red);

    //section
    --section-light-cl: var(--white);
    --section-dark-cl: var(--main-dark);
    --section-light-title-cl: var(--dark-grey);
    --section-dark-title-cl: var(--dark-white);
    --section-light-title-hover-cl: var(--black);
    --section-dark-title-hover-cl: var(--white);
    --section-title-icon-cl: var(--light-grey2);
    --section-title-bar-cl: var(--red);
    --section-title-glimmer-cl: var(--yellow);

    //navigation colors
    --nav-btn-cl: var(--orange);
    --nav-btn-active-cl: var(--red);
    --nav-bar-main-cl: var(--main-dark);
    --nav-bar-bottom-bar-cl: var(--yellow);
    --nav-bar-link-cl: var(--light-grey);
    --nav-bar-link-active-cl: var(--white);
    --nav-bar-link-hover-cl: var(--red);
    --nav-mobile-bg-cl: radial-gradient(var(--yellow), var(--yellow), var(--main-dark));
    --nav-mobile-link-cl: var(--dark-grey);
    --nav-mobile-link-active-cl: var(--black);

    //scrollBtn
    --scrollBtn-cl: var(--orange);
    --scrollBtn-active-cl: var(--red);

    //section navigation
    --scroll-btns-cl: var(--orange);
    --scroll-btns-hover-cl: var(--orange-hover);
    --icon-start-cl: var(--main-dark);
    --icon-about-cl: var(--light-green);
    --icon-projects-cl: var(--blue);
    --icon-skills-cl: var(--green);
    --icon-contact-cl: var(--red);

    //links
    --inline-link-light-cl: var(--grey);
    --inline-link-light-cover-cl: var(--green);
    --inline-link-dark-cl: var(--light-grey2);
    --inline-link-dark-cover-cl: var(--blue);

    //icon-links
    --icon-light-cl: var(--light-grey);
    --icon-light-hover-cl: var(--green);
    --icon-dark-cl: var(--dark-grey);
    --icon-dark-hover-cl: var(--blue);
    --icon-light-round-cl: var(--main-dark);
    --icon-light-round-bg-cl: var(--light-grey2);
    --icon-dark-round-cl: var(--dark-white);
    --icon-dark-round-bg-cl: var(--dark-grey);
    --icon-light-round-bg-hover-cl: var(--white);
    --icon-light-round-hover-cl: var(--black);
    --icon-dark-round-bg-hover-cl: var(--black);
    --icon-dark-round-hover-cl: var(--white);

    //input
    --input-bg-cl: var(--dark-grey2);
    --input-bg-focus-cl: var(--main-dark);
    --input-border-focus-cl: var(--light-green);
    --input-border-error-cl: var(--red);
    --input-font-cl: var(--white);
    --input-label-cl: var(--dark-grey2);

    //button
    --button-font-cl: var(--white);
    --button-bg-cl: var(--red);
    --button-positive-cl: var(--green);

    //paragraph
    --paragraph-light-cl: var(--dark-white);
    --paragraph-dark-cl: var(--main-dark);

    //headers
    --small-header-light-cl: var(--light-grey);
    --small-header-dark-cl: var(--dark-grey);

    //loader
    --loader-cl: var(--light-green);

    //project card
    --front-side-bg-cl: var(--white);
    --back-side-bg-cl: linear-gradient(115deg, #f6f694, #ffff66, #ffff00);
    --lang-bar-font-cl: var(--white);
    --polish-lang-bar-cl: var(--red);
    --english-lang-bar-cl: var(--dark-blue);

    //project modal
    --project-modal-bg-cl: var(--white);

    //gallery
    --gallery-btns-cl: var(--orange);
    --gallery-btns-disabled-cl: var(--grey);
    --gallery-btn-close-cl: var(--dark-orange);

    //skills wheel
    --skills-wheel-cl: var(--main-dark);
    --skills-wheel-inside-cl: var(--white);
    --skills-wheel-inside-icon-cl: var(--light-red);
    --skill-wheel-inside-icon-hover-cl: var(--red);
    --skill-wheel-part-hover-cl: var(--very-light-green);
    --skill-wheel-part-active-cl: var(--very-light-green);
    --skill-wheel-js-cl: var(--yellow);
    --skill-wheel-node-cl: var(--green);
    --skill-wheel-react-cl: var(--blue);
    --skill-wheel-database-cl: var(--green);

    //skills info box
    --skills-info-box-cl: var(--white);
}