@import '../../../../styles/animations.scss';

.root {
  margin: 1.5rem 0;

  .link {
    color: var(--nav-mobile-link-cl);
    font-size: 2rem;
    cursor: pointer;

    .icon {
      opacity: 0;
    }

    .name {
      font-weight: 700;
      margin: 0 1rem;
    }

    &:hover {
      color: var(--nav-mobile-link-active-cl);
    }

    &.active {
      color: var(--nav-mobile-link-active-cl);

      .icon {
        opacity: 1;
        animation: shineRed .8s infinite;
      }
    }
  }

  @media (max-height: 359px) {
    margin: .8rem 0;

    .link {
      font-size: 1.8rem;
    }
  }
}