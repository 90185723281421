.root {
  font-size: 1.7rem;
  padding: 6px 18px;
  border-radius: 6px;
  font-weight: bold;
  border: none;
  color: var(--button-font-cl);
  transform: translate(0, 0);
  background-color: var(--button-bg-cl);
  box-shadow: 2px 2px 4px #555;
  outline: none;
  cursor: pointer;
  transition: .2s;

  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px 6px #555;
  }

  &:active {
    transform: translate(0, 0);
    box-shadow: none;
  }

  &.small {
    font-size: 1.3rem;
    padding: 4px 10px;
  }

  &.positive {
    background-color: var(--button-positive-cl);
  }
}