@import '../../../styles/animations.scss';

.root {
  text-align: center;
  padding: 3rem;
  font-size: 2.6rem;

  .title {
    position: relative;
    display: inline-block;
    color: var(--setion-light-title-cl);
    cursor: default;
    transition: .2s;

    .icon,
    .text {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .icon {
      color: var(--section-title-icon-cl);
      opacity: 0.3;
      transform: translate(-50%, -50%) scale(1.4);
      transition: .3s;
    }

    .text {
      width: 100%;
      text-align: center;
      transform: translate(-50%, -50%);
    }

    .textPlaceholder {
      opacity: 0;
    }

    &::before,
    &::after {
      position: absolute;
      content: '';
      top: 110%;
    }

    &::before {
      width: 120%;
      left: 50%;
      transform: translate(-50%);
      border-radius: 1rem;
      height: .4rem;
      background: linear-gradient(to right, transparent, var(--section-title-bar-cl), transparent);
    }

    &::after {
      width: 0;
      left: -10px;
      height: 0;
      background-color: transparent;
      box-shadow: 0 0 10px 6px var(--section-title-glimmer-cl);
      visibility: hidden;
    }

    &:hover {
      color: var(--section-light-title-hover-cl);

      .icon {
        transform: translate(-50%, -50%) scale(.6);
      }

      &::after {
        visibility: visible;
        animation: moveInXDirection 1.5s infinite;
      }
    }
  }

  &.dark {
    .title {
      color: var(--section-dark-title-cl);

      &:hover {
        color:var(--section-dark-title-hover-cl);
      }
    }
  }

  @media (max-width: 1280px) {
    padding: 2.6rem;
    font-size: 2.2rem;

    .title {
      &::before {
        height: .3rem;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 2.4rem;
    font-size: 2rem;
  }

  @media (max-width: 800px) {
    padding: 1.8rem;
    font-size: 1.8rem;
  }

  @media (max-width: 500px) {
    padding: 1.4rem;
    font-size: 1.6rem;
  }
}