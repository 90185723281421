@import '../../../styles/animations.scss';

.root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;

  & div {
    position: absolute;
    width: 46px;
    height: 46px;
    margin: 4px;
    border: 4px solid var(--loader-cl);
    border-radius: 50%;
    animation: rotateLoader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--loader-cl) transparent transparent transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }

  & div:nth-child(2) {
    animation-delay: -0.3s;
  }

  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
}