.root {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(110%);
  height: 3.7rem;
  transition: transform .5s;
  background-color: var(--nav-bar-main-cl);
  box-shadow: 0 2px 10px 2px var(--main-dark);

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    height: .3rem;
    width: 100%;
    background: linear-gradient(to right, var(--nav-bar-bottom-bar-cl), var(--nav-bar-bottom-bar-cl), transparent);
  }

  .navList {
    list-style: none;
    display: flex;
    height: 100%;
    transform: skew(-20deg);
  }

  &.active {
    transform: translateX(0);
  }

  @media (max-width: 700px) {
    height: 3.4rem;
  }

  @media (max-width: 600px) {
    display: none;
  }
}