.root {
  flex: 1;
  max-width: 500px;
  min-width: 280px;
  padding: 1rem 4rem;
  text-align: center;
  font-size: 1.6rem;

  .image {
    width: 90%;
  }

  .info {
    margin-top: 2rem;
  }

  .description {
    margin-top: 1rem;
  }

  @media (max-width: 500px) {
    .description {
      display: none;
    }
  }

}