@import '../../../styles/animations.scss';

.root {
  position: fixed;
  display: block;
  bottom: 1.5rem;
  width: 5rem;
  height: 3rem;
  left: 50%;
  transform: translate(-50%, 200px);
  color: var(--scrollBtn-cl);
  font-size: 3rem;
  transition: .5s;
  cursor: pointer;

  .icon {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    color: inherit;
  }

  .icon:nth-child(1) {
    top: 0;
    opacity: 0;
  }

  .icon:nth-child(2) {
    top: 1.2rem;
  }

  &:hover {
    color: var(--scrollBtn-active-cl);

    .icon:nth-child(1) {
      opacity: 1;
    }
  }

  &.active {
    transform: translate(-50%, 0);
  }
}