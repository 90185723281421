.root {
  perspective: 900px;
  position: relative;
  width: 250px;
  height: 400px;
  margin: 2rem;
  text-align: center;

  .frontSide,
  .backSide {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    overflow: hidden;
    backface-visibility: hidden;
    box-shadow: 4px 4px 4px #000;
    transition: .6s;
  }

  .frontSide {
    background-color: var(--front-side-bg-cl);
    transform: translate(-50%, -50%) rotateY(0);

    .actionsFace {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: 2rem;
    }

    .langBar {
      position: absolute;
      text-align: center;
      transform: rotate(45deg);
      color: var(--lang-bar-font-cl);
      text-transform: uppercase;
      box-shadow: 2px 2px 4px #222;

      &_0 {
        top: 8px;
        right: -35px;
        width: 100px;
      }

      &_1 {
        top: 20px;
        right: -42px;
        width: 140px;
        transform: rotate(45deg);
      }

      &.pl {
        background-color: var(--polish-lang-bar-cl);
      }

      &.eng {
        background-color: var(--english-lang-bar-cl);
      }
    }
  }

  .backSide {
    padding: 1rem;
    background: var(--back-side-bg-cl);
    transform: translate(-50%, -50%) rotateY(-180deg);

    .actionsBack {
      position: absolute;
      bottom: 5px;
      left: 5px;
      font-size: 2rem;
    }

    .technology {
      text-align: left;
      font-size: 1.5rem;

      .technologyName {
        margin-left: 1rem;
        font-size: 1.6rem;
        font-weight: bold;
      }

      .nestedTechnologies {
        margin: 0 0 .8rem 2rem;
      }
    }
  }

  &.rotated {
    .frontSide {
      transform: translate(-50%, -50%) rotateY(180deg);
    }
    .backSide {
      transform: translate(-50%, -50%) rotateY(0);
    }
  }

  .content {
    margin: .6rem 0;
  }

  .imageWrapper {
    position: relative;
    height: 250px;
    transition: .2s;
    overflow: hidden;

    .actions {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50px) scale(0);
      font-size: 3.2rem;
      transition: .2s .2s;

      p {
        font-size: 1.4rem;
      }
    }

    img {
      display: block;
      height: 250px;
      opacity: 1;
      transform: scale(1);
      transition: .6s;
    }

    &:hover {
      .actions {
        transform: translate(-50%, -50px) scale(1);
      }

      img {
        transform: scale(.01);
        opacity: .5;
      }
    }
  }

  .description {
    margin: 1.4rem 0;
    font-size: 1.4rem;
  }

  .btnWrapper {
    opacity: .8;
    transition: .2s;
  }

  &:hover {
    .btnWrapper {
      opacity: 1;
    } 
  }
}