@import '../../../styles/animations.scss';

.root {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align:  center;

  .title {
    font-size: 4.2rem;
    padding: 0 .5rem;
    color: var(--title-cl);

    .greeting {
      display: inline-block;
      margin-right: 1.6rem;
      animation: enterFromLeft 1.5s both;
    }

    .name {
      display: inline-block;
      color: var(--title-name-cl);
      animation: enterFromRight 1.5s both;
    }
  }

  .description {
    margin-top: 3.2rem;
    font-size: 3.2rem;
    color: var(--title-description-cl);
    animation: showFromBottom .8s 1.2s both;
  }

  .link {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    color: var(--title-link-cl);
    font-size: 2.2rem;
    margin-top: 4.2rem;
    padding: .8rem 2.8rem;
    border: 1px solid var(--title-link-cl);
    border-radius: 6px;
    transition: .2s;
    animation: showFromBottom .8s 1.8s both;

    .linkText {
      display: inline-block;
    }

    &:hover {
      border-color: var(--title-link-active-cl);

      .linkText {
        animation: pulsate 1s infinite;
      }
    }

    &:active {
      background-color: var(--title-link-active-cl);
    }
  }

  .arrows {
    position: relative;
    margin-top: .5rem;
    opacity: 0;

    .icon {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      color: var(--title-link-cl);
      font-size: 2rem;
    }

    .icon:first-child {
      top: 0;
      animation: shineRed .9s infinite;
    }

    .icon:nth-child(2) {
      top: 10px;
      animation: shineRed .9s .3s infinite;
    }

    .icon:nth-child(3) {
      top: 20px;
      animation: shineRed .9s .6s infinite;
    }
  }

  .link:hover + .arrows {
    opacity: 1;
  }

  @media (max-width: 1024px) {
    .title {
      font-size: 3.6rem;
    }

    .description {
      font-size: 3rem;
    }

    .link {
      font-size: 2rem
    }
  }

  @media (max-width: 600px) {
    .title {
      font-size: 3rem;
    }

    .link {
      font-size: 1.6rem;
    }

    .description {
      margin-top: 2.4rem;
      font-size: 2.4rem;
    }
  }

  @media (max-width: 500px) {
    .title {
      font-size: 2.2rem;

      .greeting {
        margin-right: 1.2rem;
      }
    }

    .description {
      font-size: 1.8rem;
    }
  }
}