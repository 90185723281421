@keyframes enterFromLeft {
  0% {
    transform: translate(-150%, -200px);
    opacity: 0;
  }

  60% {
    transform: translate(50px, -50px);
    opacity: 0.8;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes enterFromRight {
  0% {
    transform: translate(150%, -200px);
    opacity: 0;
  }

  60% {
    transform: translate(-50px, 50px);
    opacity: 0.8;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes showFromBottom {
  0% {
    opacity: 0;
    transform: translateY(150px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes shineRed {
  0% {
    color: var(--white);
  }

  49% {
    color: var(--white);
  }

  50% {
    color: var(--red)
  }

  100% {
    color: var(--red)
  }
}

@keyframes moveInXDirection {
  0% {
    left: -10%;
  }

  50% {
    left: 110%;
  }

  100% {
    left: -10%;
  }
}

@keyframes rotateLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}