.root {
  .wheel {
    position: relative;
    display: flex;
    box-sizing: content-box;
    flex-wrap: wrap;
    width: 500px;
    max-width: 80vw;
    height: 500px;
    max-height: 80vw;
    border-radius: 50%;
    margin: 12rem auto;
    transform: rotate(0);
    transition: transform .5s;
  
    .crossX,
    .crossY,
    .circleInside {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--skills-wheel-inside-cl);
    }
  
    .circleInside {
      border-radius: 50%;
      width: 60%;
      height: 60%;
    }
  
    .crossX {
      width: 100%;
      height: 2rem;
      max-height: 2vw;
    }
  
    .crossY {
      height: 100%;
      width: 2rem;
      max-width: 2vw;
    }
  
    .part {
      box-sizing: content-box;
      position: relative;
      overflow: hidden;
      flex: 0 0 50%;
      height: 50%;
  
      &.active {
        .circle {
          border: 100px solid var(--skill-wheel-part-active-cl);
        }
      }
    }
  
    .circle {
      position: absolute;
      width: 200%;
      height: 200%;
      border-radius: 50%;
      border: 100px solid var(--skills-wheel-cl);
      transform: translate(-50%, -50%);
      cursor: pointer;
      transition: .2s;
  
      &:hover {
        border: 100px solid var(--skill-wheel-part-hover-cl);
      }
    }
  
    .icon {
      display: block;
      position: absolute;
      font-size: 8rem;
      top: 50%;
      left: 50%;
  
      &:hover {
        cursor: pointer;
      }
    }
  
    .powerOff {
      transform: translate(-50%, -50%);
      color: var(--skills-wheel-inside-icon-cl);
      transition: .2s;
  
      &:hover {
        color: var(--skill-wheel-inside-icon-hover-cl);
      }
    }
  
    .js {
      .circle {
        top: 100%;
        left: 100%;
      }
  
      .icon {
        color: var(--skill-wheel-js-cl);
        transform: translate(-80%, -60%) rotate(-45deg);
      }
    }
  
    .react {
      .circle {
        top: 100%;
        left: 0;
      }
  
      .icon {
        color: var(--skill-wheel-react-cl);
        transform: translate(-30%, -70%) rotate(-45deg);
      }
    }
  
    .node {
      .circle {
        top: 0;
        left: 100%;
      }
  
      .icon {
        color: var(--skill-wheel-node-cl);
        transform: translate(-60%, -30%) rotate(-135deg);
      }
    }
  
    .database {
      .circle {
        top: 0;
        left: 0;
      }
  
      .icon {
        font-size: 6rem;
        color: var(--skill-wheel-database-cl);
        transform: translate(-20%, -20%) rotate(-225deg);
      }
    }
  
    @media (max-width: 1024px) {
      margin: 8rem auto 8rem 3rem;
      width: 300px;
      height: 300px;
  
      .icon {
        font-size: 5rem;
      }
  
      .database {
        .icon {
          font-size: 4rem;
        }
      }
    }
  
    @media (max-width: 800px) {
      margin: 8rem auto 8rem 1.5rem;
      width: 250px;
      height: 250px;
  
      .icon {
        font-size: 4.5rem;
      }
  
      .database {
        .icon {
          font-size: 3.8rem;
        }
      }
    }
  
    @media (max-width: 600px) {
      margin: 4rem auto 40rem auto;
      width: 200px;
      height: 200px;
  
      .icon {
        font-size: 3.8rem;
      }
  
      .database {
  
  
        .icon {
          font-size: 3rem;
        }
      }
    }
  }

  @media (max-width: 600px) {
    transform: rotate(180deg);
  }
}

