.root {
  position: fixed;
  background-color: transparent;
  border: none;
  outline: none;
  top: 1rem;
  right: 1rem;
  width: 4rem;
  height: 2.2rem;
  cursor: pointer;

  &::after,
  &::before {
    display: block;
    content: '';
  }

  .bar,
  &::before,
  &::after {
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    left: 50%;
    background-color: var(--nav-btn-cl);
    transition: .2s;
  }

  .bar {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    top: 0;
    transform: translateX(-50%);
  }

  &::after {
    top: 100%;
    transform: translate(-50%, -100%); 
  }

  &:hover {
    .bar {
      transform: translate(-50%, -50%) scale(1.1);
    }

    &::before {
      transform: translate(-50%, -50%) scale(1.1);
    }

    &::after {
      transform: translate(-50%, -50%) scale(1.1); 
    }
  }

  &.active {
    .bar {
      opacity: 0;
    }

    &::before {
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg) scale(.6);
      background-color: var(--nav-btn-active-cl);
    }

    &::after {
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg) scale(.6); 
      background-color: var(--nav-btn-active-cl);
    }
  }

  &.active:hover {
    &::before {
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg) scale(.8);
    }

    &::after {
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg) scale(.8); 
    }
  }
}