.root {
  position: fixed;

  bottom: 30px;
  right: 30px;
  transform: translateY(200px);
  transition: .5s;

  &.shown {
    transform: translateY(0);
  }

  .prevNextLink {
    display: block;
    position: relative;
    width: 40px;
    height: 30px;
    color: var(--scroll-btns-cl);
    transform: scale(1);
    transition: .2s;

    &:hover {
      transform: scale(1.1);
      color: var(--scroll-btns-hover-cl);
    }

    &:active {
      transform: scale(1);
      color: var(--scroll-btns-cl);
    }

    .icon {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 40px;
      cursor: pointer;

      &:first-child {
        top: 0;
      }
      &:nth-child(2) {
        top: 12px;
      }
    }

    &.inactive {
      visibility: hidden;
    }
  }

  .currentLink {
    display: none;
    height: 30px;
    text-align: center;
    font-size: 30px;
    line-height: 30px;
    margin-top: 17px;
    cursor: pointer;

    &.start {
      color: var(--icon-start-cl);
    }

    &.about {
      color: var(--icon-about-cl);
    }

    &.projects {
      color: var(--icon-projects-cl);
    }

    &.skills {
      color: var(--icon-skills-cl);
    }

    &.contact {
      color: var(--icon-contact-cl);
    }

    &.active {
      display: block;
    }
  }

  @media (max-width: 800px) {
    bottom: 30px;
    right: 20px;

    .prevNextLink {

      .icon {
        font-size: 34px;
      }
    }

    .currentLink {
      height: 26px;
      font-size: 26px;
      margin-top: 14px;
    }
  }

  @media (max-width: 500px),
  (max-height: 500px) {
    right: 8px;
    bottom: 12px;

    .prevNextLink {

      .icon {
        font-size: 30px;

        &:first-child {
          top: 0;
        }
        &:nth-child(2) {
          top: 10px;
        }
      }
    }

    .currentLink {
      height: 24px;
      font-size: 24px;
      margin-top: 6px;
      margin-bottom: 2px;
    }
  }
}