.root {
  display: flex;
  width: 1280px;
  height: 600px;
  max-width: 90%;
  margin: 4rem auto;
  box-shadow: 4px 4px 4px #555;
  background: linear-gradient(105deg, rgba(yellow, 0.8) 50%, rgba(255, 255, 255, 0.6) 50%), url(./laptop.jpg);
  background-position: center;
  background-size: cover;

  .halfPart {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    flex: 50%;

    .form {
      max-width: 100%;
    }
  }

  .buttonWrapper {
    margin-top: 4rem;
    text-align: center;
  }

  @media (max-width: 620px) {
    max-width: 100%;

    .halfPart {
      max-width: 100%;
    }
  }
}