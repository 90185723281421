.root {
  height: 100%;

  .box {
    height: 100%;
    border-left: 2px solid var(--yellow-light);
    border-right: 2px solid var(--yellow-light);
    transform: skew(-20deg);
  }

  .button {
    height: 100%;
    padding: 0 8px;
    font-size: 1.7rem;
    cursor: pointer;
    color: var(--white);
    background-color: transparent;
    border: none;
    transition: .2s;

    &:hover, 
    &.active {
      background-color: var(--yellow-light);
      font-weight: bold;
    }

    &:first-child:hover,
    &:first-child.active {
      color: var(--red);
    }

    &:nth-child(2):hover,
    &:nth-child(2).active {
      color: var(--dark-blue);
    }
  }

  @media (max-width: 700px) {

    .button {
      font-size: 1.6rem;
    }
  }
}