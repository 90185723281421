@mixin inputField {
  position: relative;
  margin: 3rem 0;

  .input {
    display: inline-block;
    padding: 10px 8px 6px 8px;
    font-size: 1.8rem;
    background-color: var(--input-bg-cl);
    font-family: var(--main-font);
    border-radius: 2px;
    border: none;
    outline: none;
    width: 25rem;
    max-width: 100%;
    color: var(--input-font-cl);
    border-bottom: 3px solid var(--input-bg-cl);
    box-shadow: 3px 3px 4px #000;
    transition: .2s;
  
    &:focus {
      background-color: var(--input-bg-focus-cl);
      border-bottom: 3px solid var(--input-border-focus-cl);
      box-shadow: 2px 2px 3px #000;
    }
  }

  .label {
    position: absolute;
    top: -17px;
    left: 7px;
    font-size: 12px;
    color: #999;
    transform: translate(9px, 28px) scale(1.4);
    visibility: hidden;
    transition: .2s;
  }

  .input:not(:placeholder-shown) + .label {
    visibility: visible;
    color: var(--input-label-cl);
    transform: translate(0, 0) scale(1);
  }

  &.error {
    .input {
      border-color: var(--input-border-error-cl);
    }

    .input:not(:placeholder-shown) + .label {
      color: var(--input-border-error-cl);
    }
  }
}