*, 
*::before, 
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  font-family: var(--main-font);

  @media (max-width: 1280px) {
    font-size: 59%;
  }
}