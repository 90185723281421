.information {
  margin: 8rem auto;

  .iconLink {
    text-align: center;
    flex:  1;
    max-width: 500px;
    padding: 0 4rem;
    font-size: 16rem;
  }

  .informationDetails {
    flex: 3;
    max-width: 1000px;
    font-size: 1.9rem;
    text-align: justify;
    padding-right: 4rem;

    .text {
      margin-top: 1rem;
    }
  }

  @media (max-width: 1024px) {
    .iconLink {
      font-size: 14rem;
      padding: 0 2rem;
    }

    .informationDetails {
      font-size: 1.7rem;
    }
  }

  @media (max-width: 800px) {
    margin: 5rem auto;

    .iconLink {
      display: none;
    }

    .informationDetails {

      h3 {
        text-align: center;
      }

      padding: 0 2rem;
    }
  }
}